import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PharmacyListComponent } from './pages/pharmacy-list/pharmacy-list.component';
import { PharmacyAddComponent } from './pages/pharmacy-add/pharmacy-add.component';
import { PharmacyEditComponent } from './pages/pharmacy-edit/pharmacy-edit.component';

import { AuthGuard } from '../../core/guards/auth.guard';
import { redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    component: PharmacyListComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin, title: '薬局一覧' },
  },
  {
    path: 'add',
    component: PharmacyAddComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin, title: '薬局登録' },
  },
  {
    path: ':id/edit',
    component: PharmacyEditComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin, title: '薬局編集' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PharmacyRoutingModule {}
