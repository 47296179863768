import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicineQuestionComponent } from './components/medicine-question.component';
import { MedicineQuestionRoutingModule } from './medicine-question-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [MedicineQuestionComponent],
  imports: [
    CommonModule,
    CoreModule,
    MedicineQuestionRoutingModule,
    ReactiveFormsModule,
  ],
})
export class MedicineQuestionModule {}
