<header class="type-login">
  <section class="glb-header01">
    <h1 class="glb-header01-logo">
      <img src="/assets/images/common/logo_03.svg" alt="" />
    </h1>
  </section>
</header>

<main class="type-login">
  <section class="glb-login01">
    <div class="glb-login01-box">
      <div class="glb-login01-cont" [class.is-error]="isFailed$ | async">
        <h2 class="glb-login01-ttl">社内管理画面</h2>
        <ul class="glb-error01">
          <li>ログイン失敗しました</li>
        </ul>
        <button class="glb-btn01 type-google" (click)="googleLogin()">Googleでログイン</button>
      </div>
    </div>
  </section>
</main>

<footer class="type-login">
  <section class="glb-footer01">
    <small>Copyright © KAKEHASHI Inc. All Rights Reserved.</small>
  </section>
</footer>
