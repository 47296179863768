import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { LineOfficialAccount } from '../../../../core/models';

import { OfficialAccountService } from '../../services/official-account.service';

@Component({
  selector: 'app-official-account-form',
  templateUrl: './official-account-form.component.html',
})
export class OfficialAccountFormComponent implements OnInit {
  @Input() actionName: string;
  @Input() isFailed$: BehaviorSubject<boolean>;
  @Input() isSubmitting$: BehaviorSubject<boolean>;

  @Output() submitEvent = new EventEmitter<LineOfficialAccount>();

  readonly officialAccountId = this.route.snapshot.paramMap.get('id');

  officialAccountForm = this.fb.group({
    name: ['', [Validators.required]],
    corporationName: ['', [Validators.required]],
    isTrackingUrlEnabled: [false, [Validators.required]],
    isLineSettingLocked: [false, [Validators.required]],
  });

  LINE_SETTING_LOCKED_SELECT_VALUES = [
    { value: false, label: '編集可' },
    { value: true, label: '編集禁止' },
  ];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private officialAccountService: OfficialAccountService
  ) {}
  submitOfficialAccount(): void {
    if (this.officialAccountForm.invalid) {
      return;
    }

    const lineOfficialAccount: LineOfficialAccount = {
      name: this.name.value,
      corporation_name: this.corporationName.value,
      is_tracking_url_enabled: this.isTrackingUrlEnabled.value,
      is_line_setting_locked: this.isLineSettingLocked.value,
    };

    this.submitEvent.emit(lineOfficialAccount);
  }

  get name(): AbstractControl {
    return this.officialAccountForm.get('name');
  }

  get corporationName(): AbstractControl {
    return this.officialAccountForm.get('corporationName');
  }

  get isLineSettingLocked(): AbstractControl {
    return this.officialAccountForm.get('isLineSettingLocked');
  }

  get isTrackingUrlEnabled(): AbstractControl {
    return this.officialAccountForm.get('isTrackingUrlEnabled');
  }

  ngOnInit(): void {
    if (this.officialAccountId) {
      this.officialAccountService
        .getOfficialAccount(this.officialAccountId)
        .subscribe((data: LineOfficialAccount) => {
          this.officialAccountForm.setValue({
            name: data.name,
            corporationName: data.corporation_name,
            isTrackingUrlEnabled: data.is_tracking_url_enabled,
            isLineSettingLocked: data.is_line_setting_locked,
          });
        });
    }
  }
}
