import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services';
import { Observable } from 'rxjs';
import { ManualFileAdmin } from '../../../core/models';

@Injectable({
  providedIn: 'root',
})
export class ManualService extends ApiService {
  getPresignedUrl(fileType: string): Observable<ManualFileAdmin> {
    return this.get$<ManualFileAdmin>(`manual_file_admin/?file_type=${fileType}`);
  }

  putFile(presignedUrl: string, file: any): Observable<any> {
    return this.putFileToS3$(presignedUrl, file);
  }
}
