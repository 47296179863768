import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LineOfficialAccount } from '../../../core/models';
import { ApiService } from '../../../core/services';

@Injectable({
  providedIn: 'root',
})
export class OfficialAccountService extends ApiService {
  readonly officialAccounts$ = new BehaviorSubject<
    LineOfficialAccount[] | null
  >([]);

  getOfficialAccounts(): void {
    this.get$<LineOfficialAccount[]>(`line_official_accounts/`).subscribe(
      (officialAccounts) => this.officialAccounts$.next(officialAccounts) // error, completeは無視
    );
  }

  getOfficialAccount(
    officialAccountId: string
  ): Observable<LineOfficialAccount> {
    return this.get$<LineOfficialAccount>(
      `line_official_accounts/${officialAccountId}/`
    );
  }

  createOfficialAccount(
    lineOfficialAccount: LineOfficialAccount
  ): Observable<LineOfficialAccount> {
    return this.post$<LineOfficialAccount>(
      `line_official_accounts/`,
      JSON.stringify(lineOfficialAccount)
    );
  }

  editOfficialAccount(
    officialAccountId: string,
    lineOfficialAccount: LineOfficialAccount
  ): Observable<LineOfficialAccount> {
    return this.put$<LineOfficialAccount>(
      `line_official_accounts/${officialAccountId}/`,
      JSON.stringify(lineOfficialAccount)
    );
  }
  
}
