<div class="page-wrap d-flex flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center">
        <span class="display-1 d-block">404</span>
        <div class="mb-4 lead">お探しのページは存在しません</div>
        <a routerLink="/" class="btn btn-link">TOPページへ戻る</a>
      </div>
    </div>
  </div>
</div>
