import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SessionService } from '../../services';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  readonly isLogin$ = this.afAuth.user.pipe(map((user) => !!user));
  readonly isClose$ = new BehaviorSubject(false);
  @Input() isProduction = environment.production;

  constructor(
    private sessionService: SessionService,
    private afAuth: AngularFireAuth
  ) {}

  ngOnInit(): void {}

  menu() {
    this.isClose$
      .pipe(
        first(),
        map((isClose) => !isClose)
      )
      .subscribe((isClose) => this.isClose$.next(isClose));
  }

  logout() {
    this.sessionService.logout();
  }
}
