import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MaintenanceService } from '../services/maintenance.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/core/components/confirm-dialog/confirm-dialog.component';
import { Maintenance } from 'src/app/core/models';
import { filter, switchMap } from 'rxjs/operators';
import { datadogLogs } from '@datadog/browser-logs';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceComponent implements OnInit {

  readonly maintenances$ = this.maintenanceService.maintenances$;

  constructor(
    private maintenanceService: MaintenanceService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.maintenanceService.getMaintenances();
  }

  openDialog(maintenance: Maintenance): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: maintenance.is_maintenance
          ? maintenance.name + 'サービス再開しますか？'
          : maintenance.name + 'メンテナンス中にしますか？',
        confirmText: maintenance.is_maintenance ? '再開する' : 'メンテナンス中にする',
      },
      disableClose: false,
    });

    dialogRef.afterClosed().pipe(
      filter((result) => result),
      switchMap(() => this.maintenanceService.toggleMaintenceFlag(maintenance.key))
    ).subscribe({
      next: () => {
        this.maintenanceService.getMaintenances();
      },
      error: (err) => {
        datadogLogs.logger.error(JSON.stringify(err));
        alert('メンテナンスフラグの更新に失敗しました。');
      }
    });
  }
}
