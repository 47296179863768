<main>
  <section class="glb-section02">
    <div class="glb-section02-ttl">
      <p class="glb-section02-text">社内ユーザー管理</p>
    </div>
  </section>

  <section class="glb-section01 type-start">
    <div class="glb-section01-cont type-narrow">
      <div class="glb-section01-ttl">
        <h2 class="glb-ttl01">社内ユーザー管理</h2>
      </div>

      <div *ngIf="(loginUser$ | async)?.is_admin">
        <form
          class="flexBox"
          [formGroup]="createForm"
          (ngSubmit)="createStaff()"
        >
          <input
            type="email"
            class="glb-input02"
            formControlName="email"
            placeholder="kakehashi.lifeのドメインのメールアドレス"
          />

          <button type="submit" class="glb-btn04 type-large small-font">新規登録</button>
        </form>

        <span
          *ngIf="
            createForm.invalid &&
            (email.dirty ||
            email.touched)
          "
          class="glb-error02"
        >
          <div *ngIf="email.errors.required">
            メールアドレスは必須です
          </div>

          <div *ngIf="email.errors.email">
            メールアドレスのフォーマットが不正です
          </div>
        </span>
      </div>
    </div>
  </section>
  <section class="glb-section01 type-start">
    <div class="glb-section01-cont type-narrow">
      <div class="glb-section01-base">
        <ul class="glb-list01">
          <li *ngFor="let kkhsStaff of kkhsStaffs$ | async">
            <p class="glb-list01-ttl">
              <span class="glb-list01-box">
                {{kkhsStaff.email}}
                <span *ngIf="kkhsStaff.is_admin">
                  （管理者）
                </span>
              </span>
              
              <button
                *ngIf="(loginUser$ | async)?.is_admin && kkhsStaff.id !== (loginUser$ | async)?.id"
                class="glb-btn04 type-large danger"
                (click)="deleteStaff(kkhsStaff.id)"
              >
                削除
              </button>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</main>
