import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private afAuth: AngularFireAuth) {}

  get$<T>(path: string): Observable<T> {
    return this.httpOptions$().pipe(
      mergeMap((httpOptions) =>
        this.http.get<T>(environment.apiUrl + path, httpOptions)
      )
    );
  }

  post$<T>(path: string, body: string): Observable<any> {
    return this.httpOptions$().pipe(
      mergeMap((httpOptions) =>
        this.http.post<T>(environment.apiUrl + path, body, httpOptions)
      )
    );
  }

  postFile$<T>(path: string, formData: FormData): Observable<any> {
    return this.httpOptionsForFile$().pipe(
      mergeMap((httpOptions) =>
        this.http.post<T>(environment.apiUrl + path, formData, httpOptions)
      )
    );
  }

  putFile$<T>(path: string, formData: FormData): Observable<any> {
    return this.httpOptionsForFile$().pipe(
      mergeMap((httpOptions) =>
        this.http.put<T>(environment.apiUrl + path, formData, httpOptions)
      )
    );
  }

  putFileToS3$(path: string, file: FormData): Observable<any> {
    return this.http.put(path, file, {headers: {"x-amz-acl": "public-read"}});
  }

  put$<T>(path: string, body: string): Observable<any> {
    return this.httpOptions$().pipe(
      mergeMap((httpOptions) =>
        this.http.put<T>(environment.apiUrl + path, body, httpOptions)
      )
    );
  }

  patch$<T>(path: string, body: string | null): Observable<any> {
    return this.httpOptions$().pipe(
      mergeMap((httpOptions) =>
        this.http.patch<T>(environment.apiUrl + path, body, httpOptions)
      )
    );
  }

  delete$<T>(path: string): Observable<any> {
    return this.httpOptions$().pipe(
      mergeMap((httpOptions) =>
        this.http.delete<T>(environment.apiUrl + path, httpOptions)
      )
    );
  }

  private httpOptions$(): Observable<{
    [key: string]: { [key: string]: string };
  }> {
    return this.afAuth.idToken.pipe(
      first(),
      map((token) => ({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }))
    );
  }

  private httpOptionsForFile$(): Observable<{
    [key: string]: { [key: string]: string };
  }> {
    return this.afAuth.idToken.pipe(
      first(),
      map((token) => ({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }))
    );
  }
}
