<app-loading [isLoading]="isSubmitting$ | async"></app-loading>

<main>
  <section class="glb-section02">
    <div class="glb-section02-ttl">
      <p class="glb-section02-text">マニュアル・資料更新</p>
    </div>
  </section>

  <section class="glb-section01 type-start">
    <div class="glb-section01-cont type-narrow">
      <div class="glb-section01-ttl">
        <h2 class="glb-ttl01">マニュアル・資料更新</h2>
      </div>
      <p class="glb-error02" *ngIf="isProduction">ここは本番環境です。<br>Staging環境で動作確認後アップロードしてください。</p>
      <div class="glb-section01-base">
        <div class="glb-list01-cont">
          <div
            class="glb-list01-cont-in"
            *ngIf="(state$ | async) === 'Success'"
          >
            <ul class="glb-note01">
              <li>ファイルの更新に成功しました</li>
            </ul>
          </div>
          <div class="glb-list01-cont-in" *ngIf="(state$ | async) === 'Failed'">
            <ul class="glb-note01 type-error">
              <li>ファイルの更新に失敗しました</li>
              <li>{{ errorMessage }}</li>
            </ul>
          </div>
          <form [formGroup]="manualForm" (ngSubmit)="submitManualFile()">
            <div class="glb-list01-cont-in">
              <dl class="glb-dl02">
                <ng-container *ngFor="let manualFile of ManualFiles">
                  <dt class="glb-dl02-ttl">{{ manualFile.name }}</dt>
                  <dd class="glb-dl02-cont type-right">
                    <input
                      type="file"
                      class="glb-input02"
                      formControlName="manualFile"
                      accept="{{ manualFile.acceptType }}"
                      (change)="changeManualFile($event, manualFile.fileType)"
                    />
                  </dd>
                </ng-container>
              </dl>
            </div>

            <div class="glb-list01-cont-in">
              <ul class="glb-note02">
                <li>※ファイル更新は１個ずつになります。</li>
              </ul>
            </div>

            <div class="glb-list01-cont-in type-end">
              <button
                type="submit"
                class="glb-btn04 type-large"
                [disabled]="manualForm.invalid"
              >
                更新
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>
