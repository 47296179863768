<app-loading [isLoading]="isGeneratingQRCode$ | async"></app-loading>

<main>
  <section class="glb-section02">
    <div class="glb-section02-ttl">
      <p class="glb-section02-text">薬局一覧画面</p>
    </div>
  </section>
  <section class="glb-section01 type-start">
    <app-breadcrumbs [items]="breadcrumbs"></app-breadcrumbs>
  </section>
  <section class="glb-section01 type-start">
    <div class="glb-section01-cont">
      <div class="glb-section01-ttl">
        <h2 class="glb-ttl01">薬局一覧</h2>

        <div class="actions">
          <div class="query-input">
            <input
              type="text"
              name="query"
              placeholder="薬局名で検索"
              [ngModel]="query$ | async"
              (ngModelChange)="query$.next($event)"
            />
            <button
              *ngIf="(query$ | async).length > 0"
              (click)="onClearQuery()"
            >
              解除
            </button>
          </div>
          <a
            class="glb-btn03 type-end"
            routerLink="/official-accounts/{{
              officialAccountId
            }}/pharmacies/add"
            [queryParams]="{
              officialAccountName: officialAccountName
            }"
            >新規登録</a
          >
          <button
            class="glb-btn03 type-end"
            [disabled]="isDownloadDisabled"
            (click)="downloadTrackingQRCodes()"
          >
            QRダウンロード
          </button>
        </div>
      </div>
      <div class="glb-section01-base">
        <ul
          class="glb-list01"
          *ngIf="(filteredPharmacies$ | async).length !== 0; else noPharmacy"
        >
          <li *ngFor="let pharmacy of filteredPharmacies$ | async">
            <p class="glb-list01-ttl">
              <span class="glb-list01-box list-item">
                <a
                  routerLink="/official-accounts/{{
                    officialAccountId
                  }}/pharmacies/{{ pharmacy.id }}/edit"
                  [queryParams]="{
                    officialAccountName: officialAccountName,
                    pharmacyName: pharmacy.name
                  }"
                  >{{ pharmacy.name }}</a
                >
                <button
                  *ngIf="pharmacy.is_cancelled; else isNotCancelled"
                  class="glb-btn04 type-large"
                  (click)="openDialog(pharmacy)"
                >
                  再開する
                </button>

                <ng-template #isNotCancelled>
                  <button
                    class="glb-btn04 type-large danger"
                    (click)="openDialog(pharmacy)"
                  >
                    解約する
                  </button>
                </ng-template>
              </span>
            </p>
          </li>
        </ul>
        <ng-template #noPharmacy>
          <p class="empty-text">該当の薬局はありません</p>
        </ng-template>
      </div>
    </div>
  </section>
</main>
