<app-loading [isLoading]="isSubmitting$ | async"></app-loading>

<main>
  <section class="glb-section02">
    <div class="glb-section02-ttl">
      <p class="glb-section02-text">おくすりマスタ アップロード</p>
    </div>
  </section>

  <section class="glb-section01 type-start">
    <div class="glb-section01-cont type-narrow">
      <div class="glb-section01-ttl">
        <h2 class="glb-ttl01">おくすりマスタ アップロード</h2>
      </div>
      <div class="glb-section01-base">
        <div class="glb-list01-cont">
          <div
            class="glb-list01-cont-in"
            *ngIf="(state$ | async) === 'Success'"
          >
            <ul class="glb-note01">
              <li>ファイルのアップロードに成功しました</li>
              <li>{{ successMessage }}</li>
            </ul>
          </div>
          <div class="glb-list01-cont-in" *ngIf="(state$ | async) === 'Failed'">
            <ul class="glb-note01 type-error">
              <li>ファイルのアップロードに失敗しました</li>
              <li>{{ errorMessage }}</li>
            </ul>
          </div>
          <form [formGroup]="medicineForm" (ngSubmit)="submitMedicineFile()">
            <div class="glb-list01-cont-in">
              <dl class="glb-dl02">
                <dt class="glb-dl02-ttl">おくすりマスタファイル</dt>
                <dd class="glb-dl02-cont type-right">
                  <input
                    type="file"
                    class="glb-input02"
                    formControlName="medicineFile"
                    accept="text/csv"
                    (change)="changeMedicineFile($event)"
                  />
                </dd>
              </dl>
            </div>

            <div class="glb-list01-cont-in">
              <ul class="glb-note02">
                <li>※おくすりマスタはヘッダーを含めて1000行以内</li>
              </ul>
            </div>

            <div class="glb-list01-cont-in type-end">
              <button
                type="submit"
                class="glb-btn04 type-large"
                [disabled]="medicineForm.invalid"
              >
                送信
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>
