<main>
  <ng-container *ngIf="pharmacy$ | async as pharmacy">
    <app-pharmacy-form
      [actionName]="actionName"
      [isFailed$]="isFailed$"
      [errorMessage$]="errorMessage$"
      [pharmacy]="pharmacy"
      [breadcrumbs]="breadcrumbs"
      (submitEvent)="onSubmit($event)"
    ></app-pharmacy-form>
    <app-line-official-account-update-form
      [pharmacy]="pharmacy"
    ></app-line-official-account-update-form>
  </ng-container>
</main>
