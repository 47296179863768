<main>
  <section class="glb-section02">
    <div class="glb-section02-ttl">
      <p class="glb-section02-text">LINE公式アカウント一覧</p>
    </div>
  </section>
  <section class="glb-section01 type-start">
    <app-breadcrumbs [items]="breadcrumbs"></app-breadcrumbs>
  </section>
  <section class="glb-section01 type-start">
    <div class="glb-section01-cont type-narrow">
      <div class="glb-section01-ttl">
        <h2 class="glb-ttl01">LINE公式アカウント一覧</h2>
        <a class="glb-btn03 type-end" routerLink="/official-accounts/add"
          >新規登録</a
        >
      </div>
      <div class="glb-section01-base">
        <ul class="glb-list01">
          <li
            class="glb-list01-zero"
            *ngIf="(officialAccounts$ | async)?.length === 0"
          >
            登録されているLINE公式アカウントはありません。
          </li>
          <li *ngFor="let officialAccount of officialAccounts$ | async">
            <p class="glb-list01-ttl">
              <span class="glb-list01-box">
                {{ officialAccount.id }}:
                <a
                  routerLink="/official-accounts/{{ officialAccount.id }}/edit"
                >
                  {{ officialAccount.corporation_name }}
                  /
                  {{ officialAccount.name }}
                </a>
              </span>
              <span class="glb-list01-box type-end">
                <a
                  class="glb-btn04"
                  routerLink="/official-accounts/{{
                    officialAccount.id
                  }}/pharmacies"
                  [queryParams]="{
                    officialAccountName: officialAccount.name,
                    isTrackingUrlEnabled: officialAccount.is_tracking_url_enabled,
                    friendLinkUrl: officialAccount.friend_link_url ?? '',
                    basicBotId: officialAccount.basic_bot_id ?? ''
                  }"
                  >薬局一覧</a
                >
              </span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</main>
