import { CoreModule } from '../../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KkhsStaffRoutingModule } from './kkhs-staff-routing.module';
import { KkhsStaffComponent } from './components/kkhs-staff.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    KkhsStaffComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    KkhsStaffRoutingModule,
    ReactiveFormsModule,
  ]
})
export class KkhsStaffModule { }
