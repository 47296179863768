import { Component, OnInit } from '@angular/core';
import { OfficialAccountService } from '../../services/official-account.service';
import { BreadcrumbItem } from '../../../../core/components/breadcrumbs/breadcrumb-item.interface';

@Component({
  selector: 'app-official-account-list',
  templateUrl: './official-account-list.component.html',
})
export class OfficialAccountListComponent implements OnInit {
  readonly officialAccounts$ = this.officialAccountService.officialAccounts$;

  constructor(private officialAccountService: OfficialAccountService) {}

  readonly breadcrumbs: BreadcrumbItem[] = [
    { label: 'LINE公式アカウント一覧' }
  ];

  ngOnInit(): void {
    this.officialAccountService.getOfficialAccounts();
  }
}
