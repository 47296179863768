import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { environment } from 'src/environments/environment';

// mablからのリクエスト時にGoogle認証をパスさせるために、AngularFireAuthGuardをラップして利用する
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authGuard: AngularFireAuthGuard
  ) {}

  // mablからのリクエスト → Google認証をスルー ための実装を追加
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree>
  {
    // check mabl request
    if (environment.stage === 'staging' && navigator.webdriver) {
      return of(true);
    } else {
      return this.authGuard.canActivate(route, state);
    }
  }
}