<ng-container *ngIf="isLogin$ | async">
  <nav [class.is-close]="isClose$ | async">
    <section class="glb-nav01">
      <h1 class="glb-header01-logo">
        <img src="/assets/images/common/logo_04.svg" />
      </h1>
      <p class="glb-nav01-menu" (click)="menu()">
        <span class="glb-nav01-menu-icon"></span>
      </p>
      <ul class="glb-nav01-list">
        <li><a routerLink="/official-accounts">LINE公式アカウント一覧</a></li>
        <li><a routerLink="/pharmacy-bulk-register">薬局一括登録/更新</a></li>
        <li><a routerLink="/medicine">おくすりマスタ</a></li>
        <li><a routerLink="/pq">PQマスタ</a></li>
        <li><a routerLink="/medicine-question">YJ対PQマスタ</a></li>
        <li><a routerLink="/manual">マニュアル・資料更新</a></li>
        <li><a routerLink="/maintenance">メンテナンスフラグ管理</a></li>
        <li *ngIf="!isProduction">
          <a routerLink="/line-users">LINEユーザー管理(検証環境用)</a>
        </li>
        <li><a routerLink="/kkhs-staffs">社内ユーザー管理</a></li>
        <li><a routerLink="/pe-line-message">メッセージ一括配信</a></li>
        <li><a routerLink="/bulk-delivery-contents">一括配信コンテンツ</a></li>
        <li><a href="#" (click)="logout()">ログアウト</a></li>
      </ul>
    </section>
  </nav>
</ng-container>
