import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManualComponent } from './components/manual.component';
import { ManualRoutingModule } from './manual-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [ManualComponent],
  imports: [CommonModule, CoreModule, ManualRoutingModule, ReactiveFormsModule],
})
export class ManualModule {}
