import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PharmacyRoutingModule } from './pharmacy-routing.module';

import { PharmacyAddComponent } from './pages/pharmacy-add/pharmacy-add.component';
import { PharmacyEditComponent } from './pages/pharmacy-edit/pharmacy-edit.component';
import { PharmacyListComponent } from './pages/pharmacy-list/pharmacy-list.component';
import { PharmacyFormComponent } from './components/pharmacy-form/pharmacy-form.component';

import { PharmacyService } from './services/pharmacy.service';
import { CoreModule } from 'src/app/core/core.module';
import { LineOfficialAccountUpdateFormComponent } from './components/line-official-account-update-form/line-official-account-update-form.component';

@NgModule({
  declarations: [
    PharmacyAddComponent,
    PharmacyEditComponent,
    PharmacyListComponent,
    PharmacyFormComponent,
    LineOfficialAccountUpdateFormComponent,
  ],
  providers: [PharmacyService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PharmacyRoutingModule,
    CoreModule,
    FormsModule,
  ],
})
export class PharmacyModule {}
