import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SessionService } from '../../core/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  readonly isFailed$ = this.sessionService.isFailed$;

  constructor(private sessionService: SessionService) {}

  googleLogin() {
    this.sessionService.googleLogin();
  }

  ngOnInit(): void {}
}
