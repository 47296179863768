<main>
  <section class="glb-section02">
    <div class="glb-section02-ttl">
      <p class="glb-section02-text">LINEユーザー管理(検証環境用)</p>
    </div>
  </section>

  <section class="glb-section01 type-start">
    <div class="glb-section01-cont type-narrow">
      <div class="glb-section01-ttl">
        <h2 class="glb-ttl01">LINEユーザー管理(検証環境用)</h2>
      </div>
      <form
        class="flexBox"
        [formGroup]="searchForm"
        (ngSubmit)="submitSearch()"
      >
        <input
          type="text"
          class="glb-input02"
          formControlName="searchParam"
          placeholder="LINE 表示名"
        />
        <button type="submit" class="glb-btn04 type-large">検索</button>
      </form>
    </div>
  </section>
  <section class="glb-section01 type-start">
    <div class="glb-section01-cont type-narrow">
      <div
        class="glb-section01-base"
        *ngFor="let lineUser of lineUsers$ | async"
      >
        <div class="glb-list01-cont">
          <dl class="glb-dl02">
            <dt class="glb-dl02-ttl">ID</dt>
            <dd class="glb-dl02-cont">
              {{ lineUser.id }}
            </dd>
            <dt class="glb-dl02-ttl">LINE 表示名</dt>
            <dd class="glb-dl02-cont">
              {{ lineUser.display_name }}
            </dd>
            <dt class="glb-dl02-ttl">LINE ID</dt>
            <dd class="glb-dl02-cont">
              {{ lineUser.user_id }}
            </dd>
            <dt class="glb-dl02-ttl" *ngIf="lineUser.patients.length > 0">
              紐付き患者
            </dt>
            <dd class="glb-dl02-cont" *ngIf="lineUser.patients.length > 0">
              <p *ngFor="let patient of lineUser.patients">
                {{ patient.name }} (ID: {{ patient.id }})
              </p>
            </dd>
          </dl>
          <div class="glb-list01-cont-in type-end">
            <button
              class="glb-btn04 type-large danger"
              (click)="submitDelete(lineUser.id)"
            >
              削除
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
