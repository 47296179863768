import { Component } from '@angular/core';
import { PageTitleService } from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'pocket-musubi-line-kkhs-admin';

  constructor(private pageTitleService: PageTitleService) {}
}
