import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PharmacyRegistration } from '../../../../core/models';
import { PharmacyService } from '../../services/pharmacy.service';
import { datadogLogs } from '@datadog/browser-logs';
import { BreadcrumbItem } from '../../../../core/components/breadcrumbs/breadcrumb-item.interface';

@Component({
  selector: 'app-pharmacy-add',
  templateUrl: './pharmacy-add.component.html',
})
export class PharmacyAddComponent implements OnInit {
  readonly officialAccountId =
    this.route.snapshot.paramMap.get('officialAccountId');
  readonly officialAccountName = this.route.snapshot.queryParamMap.get('officialAccountName');
  readonly actionName = '登録';
  readonly isFailed$ = new BehaviorSubject(false);

  readonly errorMessage$ = new BehaviorSubject('');

  readonly breadcrumbs: BreadcrumbItem[] = [
    { label: 'LINE公式アカウント一覧', route: '/official-accounts' },
    {
      label: this.officialAccountName,
      route: `/official-accounts/${this.officialAccountId}/pharmacies`,
      params: { "officialAccountName": this.officialAccountName }
    },
    { label: '新規登録' },
  ];

  constructor(
    private pharmacyService: PharmacyService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  onSubmit(pharmacyRegistration: PharmacyRegistration): void {
    this.pharmacyService
      .createPharmacy(this.officialAccountId, pharmacyRegistration)
      .subscribe(
        () => {
          this.router.navigate([
            `/official-accounts/${this.officialAccountId}/pharmacies`,
          ]);
        },
        (err) => {
          datadogLogs.logger.error(JSON.stringify(err));
          this.isFailed$.next(true);
          this.errorMessage$.next(
            err?.error?.hasOwnProperty('detail')
              ? err?.error?.detail
              : err?.error
          );
        }
      );
  }

  ngOnInit(): void {
  }
}
