<main>
  <section class="glb-section02">
    <div class="glb-section02-ttl">
      <p class="glb-section02-text">メンテナンスフラグ管理</p>
    </div>
  </section>
  <section class="glb-section01 type-start">
    <div class="glb-section01-cont type-narrow">
      <div class="glb-section01-ttl">
        <h2 class="glb-ttl01">メンテナンスフラグ管理</h2>
      </div>
      <div class="glb-section01-base">
        <ul class="glb-list01">
          <li *ngFor="let maintenance of maintenances$ | async">
            <p class="glb-list01-ttl">
              <span class="glb-list01-box">
                {{maintenance.name}}
              </span>
              <span class="glb-list01-box type-end">
                <button
                  *ngIf='maintenance.is_maintenance; else isNoMaitenance'
                  class="glb-btn04 type-large"
                  (click)="openDialog(maintenance)">
                    サービス再開する
                </button>

                <ng-template #isNoMaitenance>
                  <button
                    class="glb-btn04 type-large danger"
                    (click)="openDialog(maintenance)"
                  >
                    メンテナンスにする
                  </button>
                </ng-template>
              </span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</main>
