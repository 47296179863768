<section class="glb-section01 type-start">
  <div class="glb-section01-cont type-narrow">
    <div class="glb-section01-ttl">
      <h2 class="glb-ttl01">Line公式アカウント紐付け変更</h2>
    </div>
    <div class="glb-section01-base" style="">
      <form [formGroup]="LineOfficialAccountForm" action="">
        <p class="glb-list01-ttl">
          <select formControlName="LineOfficialAccountId" class="glb-select01">
            <option value="">選択してください</option>
            <option
              *ngFor="let LineOfficialAccount of LineOfficialAccounts$ | async"
              [value]="LineOfficialAccount.id"
            >
              {{lineOfficialAccountName(LineOfficialAccount)}}
            </option>
          </select>
        </p>
        <div class="glb-list01-cont-in type-end" style="padding-bottom: 16px;">
          <button
            type="button"
            class="glb-btn04 type-large"
            style="margin-top: 16px;"
            [disabled]=""
            (click)="openDialog()">
            変更
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
