import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services';
import { Observable } from 'rxjs';
import { PqAdmin } from '../../../core/models';

@Injectable({
  providedIn: 'root',
})
export class PqService extends ApiService {
  sendFile(formData: FormData): Observable<PqAdmin> {
    return this.putFile$<PqAdmin>(`pq_admin/`, formData);
  }
}
