<div class="dialog">
  <p class="dialog-title" mat-dialog-title>{{ data.title }}</p>
  <p *ngIf="!!data.description">{{ data.description }}</p>
  <mat-dialog-actions>
    <button class="glb-btn05 type-large" mat-dialog-close>キャンセル</button>
    <button class="glb-btn04 type-large" [mat-dialog-close]="true">
      {{ data.confirmText }}
    </button>
  </mat-dialog-actions>
</div>
