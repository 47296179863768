import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { OfficialAccountRoutingModule } from './official-account-routing.module';
import { OfficialAccountFormComponent } from './components/official-account-form/official-account-form.component';
import { OfficialAccountEditComponent } from './pages/official-account-edit/official-account-edit.component';
import { OfficialAccountListComponent } from './pages/official-account-list/official-account-list.component';
import { OfficialAccountAddComponent } from './pages/official-account-add/official-account-add.component';

import { OfficialAccountService } from './services/official-account.service';

import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [
    OfficialAccountAddComponent,
    OfficialAccountEditComponent,
    OfficialAccountListComponent,
    OfficialAccountFormComponent,
  ],
  providers: [OfficialAccountService],
  imports: [CoreModule, CommonModule, ReactiveFormsModule, OfficialAccountRoutingModule],
})
export class OfficialAccountModule {}
