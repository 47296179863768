import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services';
import { Observable } from 'rxjs';
import { MedicineQuestionAdmin } from '../../../core/models';

@Injectable({
  providedIn: 'root',
})
export class MedicineQuestionService extends ApiService {
  sendFile(formData: FormData): Observable<MedicineQuestionAdmin> {
    return this.putFile$<MedicineQuestionAdmin>(`medicine_question_admin/`, formData);
  }
}
