import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  NgModule,
  ErrorHandler,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';

import { KkhsStaffModule } from './features/kkhs-staff/kkhs-staff.module';
import { LineUserModule } from './features/line-user/line-user.module';
import { LoginModule } from './features/login/login.module';
import { MaintenanceModule } from './features/maintenance/maintenance.module';
import { MedicineModule } from './features/medicine/medicine.module';
import { PqModule } from './features/pq/pq.module';
import { MedicineQuestionModule } from './features/medicine-question/medicine-question.module';
import { ManualModule } from './features/manual/manual.module';
import { PharmacyModule } from './features/pharmacy/pharmacy.module';
import { OfficialAccountModule } from './features/official-account/official-account.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import packageInfo from '../../package.json';
import { datadogLogs } from '@datadog/browser-logs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialogComponent } from './core/components/confirm-dialog/confirm-dialog.component';
import { ToastrModule } from 'ngx-toastr';

// DataDog
// ref. https://docs.datadoghq.com/ja/logs/log_collection/javascript/?tab=npm
datadogLogs.init({
  clientToken: 'pubb576283ac7dd5de7e5b7aa563e29d631',
  forwardErrorsToLogs: true,
  sampleRate: 100,
});
datadogLogs.addLoggerGlobalContext(
  'service',
  `pm-line-kkhs-admin-${environment.stage}`
);
datadogLogs.addLoggerGlobalContext('version', packageInfo.version);

@NgModule({
  declarations: [AppComponent, ConfirmDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    KkhsStaffModule,
    LineUserModule,
    LoginModule,
    MaintenanceModule,
    MedicineModule,
    ManualModule,
    PqModule,
    MedicineQuestionModule,
    OfficialAccountModule,
    PharmacyModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
  ],
  entryComponents: [ConfirmDialogComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
