import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MedicineQuestionService } from '../services/medicine-question.service';
import { datadogLogs } from '@datadog/browser-logs';

type State = 'Initialed' | 'Success' | 'Failed';

@Component({
  selector: 'app-medicine-question',
  templateUrl: './medicine-question.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicineQuestionComponent implements OnInit {
  readonly state$ = new BehaviorSubject<State>('Initialed');
  readonly isSubmitting$ = new BehaviorSubject(false);
  public errorMessage: string;
  public successMessage: string;

  medicineQuestionForm = this.fb.group({
    medicineQuestionFile: ['', [Validators.required]],
    medicineQuestionFileName: ['', [Validators.required]],
    medicineQuestionFileSource: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private medicineQuestionService: MedicineQuestionService
  ) {}

  get medicineQuestionFile(): AbstractControl {
    return this.medicineQuestionForm.get('medicineQuestionFile');
  }

  changeMedicineQuestionFile(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.medicineQuestionForm.patchValue({
        medicineQuestionFileSource: file,
        medicineQuestionFileName: file.name,
      });
    }
  }

  submitMedicineQuestionFile(): void {
    const formData = new FormData();
    formData.append(
      'file',
      this.medicineQuestionForm.get('medicineQuestionFileSource').value,
      this.medicineQuestionForm.get('medicineQuestionFileName').value
    );

    this.isSubmitting$.next(true);
    this.medicineQuestionService.sendFile(formData).subscribe(
      (res) => {
        this.state$.next('Success');
        this.successMessage = res.detail;
        this.medicineQuestionForm.reset();
        this.isSubmitting$.next(false);
      },
      (err) => {
        datadogLogs.logger.error(JSON.stringify(err));
        if (err.error.detail) {
          this.errorMessage = err.error.detail;
        }
        this.state$.next('Failed');
        this.isSubmitting$.next(false);
      }
    );
  }

  ngOnInit(): void {
    this.state$.next('Initialed');
    this.errorMessage = '';
    this.successMessage = '';
    this.isSubmitting$.next(false);
  }
}
