import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services';

type LineUser = {
  id: number;
  display_name: string;
  user_id: string;
  patients: Patient[];
};

type Patient = {
  id: number;
  name: string;
};

@Injectable({
  providedIn: 'root',
})
export class LineUserService extends ApiService {
  readonly lineUsers$ = new BehaviorSubject<LineUser[]>([]);

  getLineUsers(searchParam: string): void {
    let url = 'line_users/';
    if (searchParam) {
      url += `?search=${searchParam}`;
    }
    this.get$<LineUser[]>(url).subscribe((lineUsers) => {
      this.lineUsers$.next(lineUsers);
    });
  }

  deleteLineUsers(id: number): Observable<LineUser> {
    return this.delete$<LineUser>(`line_users/${id}/`);
  }
}
