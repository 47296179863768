import { Pharmacy } from './../../../../core/models/pharmacy';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { PharmacyService } from '../../services/pharmacy.service';
import { ConfirmDialogComponent } from 'src/app/core/components/confirm-dialog/confirm-dialog.component';
import { datadogLogs } from '@datadog/browser-logs';
import { filter, map, switchMap } from 'rxjs/operators';

import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BreadcrumbItem } from '../../../../core/components/breadcrumbs/breadcrumb-item.interface';

@Component({
  selector: 'app-pharmacy-list',
  templateUrl: './pharmacy-list.component.html',
  styleUrls: ['./pharmacy-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PharmacyListComponent implements OnInit {
  readonly officialAccountId =
    this.route.snapshot.paramMap.get('officialAccountId');
  readonly officialAccountName = this.route.snapshot.queryParamMap.get(
    'officialAccountName'
  );
  readonly isTrackingUrlEnabled = this.route.snapshot.queryParamMap.get(
    'isTrackingUrlEnabled'
  );
  readonly basicBotId = this.route.snapshot.queryParamMap.get('basicBotId');

  readonly friendLinkUrl = this.route.snapshot.queryParamMap.get('friendLinkUrl');

  readonly query$ = new BehaviorSubject('');

  readonly isGeneratingQRCode$ = new BehaviorSubject(false);

  readonly pharmacies$ = this.pharmacyService.pharmacies$;

  readonly breadcrumbs: BreadcrumbItem[] = [
    { label: 'LINE公式アカウント一覧', route: '/official-accounts' },
    { label: this.officialAccountName },
  ];

  constructor(
    private route: ActivatedRoute,
    private pharmacyService: PharmacyService,
    public dialog: MatDialog
  ) {}

  get isDownloadDisabled() {
    return this.basicBotId === '';
  }

  ngOnInit(): void {
    this.pharmacyService.getPharmacies(this.officialAccountId);
  }

  readonly filteredPharmacies$ = combineLatest([
    this.query$,
    this.pharmacies$,
  ]).pipe(
    map(([query, pharmacies]) => {
      if (query.length === 0) {
        return pharmacies;
      }

      const replaceQuery = query.replace(/\s+/g, '');

      return pharmacies.filter((pharmacy) =>
        pharmacy.name.replace(/\s+/g, '').includes(replaceQuery)
      );
    })
  );

  openDialog(pharmacy: Pharmacy): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: pharmacy.is_cancelled
          ? pharmacy.name + 'の利用を再開しますか？'
          : pharmacy.name + 'を解約しますか？',
        confirmText: pharmacy.is_cancelled ? '再開する' : '解約する',
      },
      disableClose: false,
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((result) => result),
        switchMap(() =>
          this.pharmacyService.togglePharmacyCancelStatus(pharmacy.id)
        )
      )
      .subscribe({
        next: () => {
          // 再取得して is_cancelled の状態を更新
          this.pharmacyService.getPharmacies(this.officialAccountId);
        },
        error: (err) => {
          datadogLogs.logger.error(JSON.stringify(err));
          alert('解約ステータスの変更に失敗しました。');
        },
      });
  }

  onClearQuery() {
    this.query$.next('');
  }

  async downloadTrackingQRCodes() {
    this.isGeneratingQRCode$.next(true);

    const zip = new JSZip();
    const officialAccountName = this.route.snapshot.queryParamMap.get(
      'officialAccountName'
    );
    const pharmacies = this.pharmacies$.getValue();

    // パフォーマンス改善 + Loading を表示させるために setTimeout で処理を分割
    setTimeout(async () => {
      await pharmacies.forEach(async (pharmacy) => {
        const filename = `${officialAccountName}_${pharmacy.name}.png`;

        const shouldUseTrackingUrl = this.isTrackingUrlEnabled === 'true';

        const url = shouldUseTrackingUrl
          ? pharmacy.tracking_url
          : this.friendLinkUrl;
        const qrcode = await QRCode.toDataURL(url);
        const base64 = qrcode.replace(/^data:image\/(png|jpg);base64,/, '');
        zip.file(filename, base64, { base64: true });
      });

      const zipFilename = `${officialAccountName}_QR.zip`;
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, zipFilename);
      });

      this.isGeneratingQRCode$.next(false);
    }, 0);
  }
}
