import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Pharmacy, PharmacyRegistration } from '../../../../core/models';
import { PharmacyService } from '../../services/pharmacy.service';
import { datadogLogs } from '@datadog/browser-logs';
import { BreadcrumbItem } from '../../../../core/components/breadcrumbs/breadcrumb-item.interface';

@Component({
  selector: 'app-pharmacy-edit',
  templateUrl: './pharmacy-edit.component.html',
})
export class PharmacyEditComponent implements OnInit {
  readonly pharmacyId = this.route.snapshot.paramMap.get('id');
  readonly officialAccountId =
    this.route.snapshot.paramMap.get('officialAccountId');
  readonly officialAccountName = this.route.snapshot.queryParamMap.get('officialAccountName');
  readonly pharmacyName = this.route.snapshot.queryParamMap.get('pharmacyName');
  readonly actionName = '更新';
  readonly errorMessage$ = new BehaviorSubject('');
  readonly isFailed$ = new BehaviorSubject(false);

  readonly pharmacy$ = new BehaviorSubject<Pharmacy>(null);

  readonly breadcrumbs: BreadcrumbItem[] = [
    { label: 'LINE公式アカウント一覧', route: '/official-accounts' },
    { label: this.officialAccountName,
      route: `/official-accounts/${this.officialAccountId}/pharmacies`,
      params: { "officialAccountName": this.officialAccountName }
    },
    { label: this.pharmacyName },
  ];

  constructor(
    private pharmacyService: PharmacyService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  onSubmit(pharmacyRegistration: PharmacyRegistration): void {
    this.pharmacyService
      .editPharmacy(
        this.officialAccountId,
        this.pharmacyId,
        pharmacyRegistration
      )
      .subscribe(
        () => {
          this.router.navigate([
            `/official-accounts/${this.officialAccountId}/pharmacies`,
          ]);
        },
        (err) => {
          datadogLogs.logger.error(JSON.stringify(err));
          this.isFailed$.next(true);
          this.errorMessage$.next(
            err?.error?.hasOwnProperty('detail')
              ? err?.error?.detail
              : err?.error
          );
        }
      );
  }

  ngOnInit(): void {
    this.pharmacyService
      .getPharmacy(this.officialAccountId, this.pharmacyId)
      .subscribe((data: Pharmacy) => {
        this.pharmacy$.next(data);
      });
  }
}
