import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ManualService } from '../services/manual.service';
import { datadogLogs } from '@datadog/browser-logs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

type State = 'Initialed' | 'Success' | 'Failed';

interface ManualFile {
  name: string;
  fileType: string;
  acceptType: string;
}
@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualComponent implements OnInit {
  readonly state$ = new BehaviorSubject<State>('Initialed');
  readonly isSubmitting$ = new BehaviorSubject(false);
  public errorMessage: string;
  @Input() isProduction = environment.production;

  ManualFiles: ManualFile[] = [
    {
      name: 'スタートアップガイド',
      fileType: 'START_UP_GUIDE',
      acceptType: 'application/pdf',
    },
    {
      name: '設定マニュアル',
      fileType: 'SETTING_MANUAL',
      acceptType: 'application/pdf',
    },
    {
      name: '患者さん向け資料',
      fileType: 'DOCUMENT_FOR_PATIENT',
      acceptType: 'application/zip',
    },
    {
      name: '設定情報記録シート',
      fileType: 'SETTING_INFO',
      acceptType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    {
      name: 'アカウント設定マニュアル',
      fileType: 'ACCOUNT_ADMIN_MANUAL',
      acceptType: 'application/pdf',
    },
    {
      name: '画像ダウンロード',
      fileType: 'IMAGE_DOWNLOAD',
      acceptType: 'application/zip',
    },
  ];

  manualForm = this.fb.group({
    manualFile: ['', [Validators.required]],
    manualFileName: ['', [Validators.required]],
    manualFileSource: ['', [Validators.required]],
    manualFileType: ['', [Validators.required]],
  });

  constructor(private fb: FormBuilder, private manualService: ManualService) {}

  get manualFile(): AbstractControl {
    return this.manualForm.get('manualFile');
  }

  changeManualFile(event: any, fileType: String): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.manualForm.patchValue({
        manualFileSource: file,
        manualFileName: file.name,
        manualFileType: fileType,
      });
    }
  }

  submitManualFile(): void {
    const isConfirm = this.isProduction ? confirm('ここは本番環境です。Staging環境での動作確認は完了していますか？') : true;
    if (isConfirm) {
      this.isSubmitting$.next(true);

      const fileType = this.manualForm.get('manualFileType').value;
      const file = this.manualForm.get('manualFileSource').value;

      this.manualService.getPresignedUrl(fileType).pipe(
        mergeMap((res) =>
          this.manualService.putFile(res.url, file)
        )
      ).subscribe(
        (res) => {
          this.state$.next('Success');
          this.manualForm.reset();
          this.isSubmitting$.next(false);
        },
        (err) => {
          datadogLogs.logger.error(JSON.stringify(err));
          if (err.error.detail) {
            this.errorMessage = err.error.detail;
          }
          this.state$.next('Failed');
          this.isSubmitting$.next(false);
        }
      );
    }
  }

  ngOnInit(): void {
    this.state$.next('Initialed');
    this.errorMessage = '';
    this.isSubmitting$.next(false);
  }
}
