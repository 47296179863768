<main>
  <section class="glb-section02">
    <div class="glb-section02-ttl">
      <p class="glb-section02-text">薬局{{ actionName }}</p>
    </div>
  </section>

  <section class="glb-section01 type-start">
    <app-breadcrumbs [items]="breadcrumbs"></app-breadcrumbs>
  </section>

  <section class="glb-section01 type-start">
    <div class="glb-section01-cont type-narrow">
      <div class="glb-section01-ttl">
        <h2 class="glb-ttl01">薬局情報</h2>
      </div>
      <div class="glb-section01-base">
        <div class="glb-list01-cont">
          <div class="glb-list01-ttl" *ngIf="isFailed$ | async">
            <p class="glb-error02">{{ errorMessage$?.value }}</p>
          </div>

          <form [formGroup]="pharmacyForm" (ngSubmit)="submitPharmacy()">
            <div class="glb-list01-cont-in">
              <dl class="glb-dl02">
                <dt class="glb-dl02-ttl">医療機関コード(10桁)</dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="text"
                    class="glb-input02"
                    formControlName="medicalInstitutionCode"
                    placeholder="1234567890"
                  />
                  <span
                    *ngIf="
                      medicalInstitutionCode.invalid &&
                      (medicalInstitutionCode.dirty ||
                        medicalInstitutionCode.touched)
                    "
                    class="glb-error02"
                  >
                    <div *ngIf="medicalInstitutionCode.errors.required">
                      医療機関コード(10桁)は必須です
                    </div>
                    <div
                      *ngIf="
                        medicalInstitutionCode.errors.medicalInstitutionCode
                      "
                    >
                      医療機関コード(10桁)が不正です
                    </div>
                  </span>
                </dd>

                <dt class="glb-dl02-ttl">候補</dt>
                <dd class="glb-dl02-cont">
                  <select formControlName="musubiPharmacy" class="glb-select01">
                    <option value="">選択してください</option>
                    <option
                      *ngFor="let musubiPharmacy of musubiPharmacies$ | async"
                      [value]="musubiPharmacy.pharmacy_id"
                    >
                      {{ musubiPharmacy.pharmacy_name }}
                    </option>
                  </select>
                </dd>

                <dt class="glb-dl02-ttl">薬局名</dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="text"
                    class="glb-input02"
                    formControlName="name"
                    placeholder="ムスビ薬局"
                    disabled
                  />
                  <span
                    *ngIf="name.invalid && (name.dirty || name.touched)"
                    class="glb-error02"
                  >
                    <div *ngIf="name.errors.required">薬局名は必須です</div>
                  </span>
                </dd>

                <dt class="glb-dl02-ttl">郵便番号</dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="text"
                    class="glb-input02"
                    formControlName="zipCode"
                    placeholder="123-4567"
                    disabled
                  />
                  <span
                    *ngIf="
                      zipCode.invalid && (zipCode.dirty || zipCode.touched)
                    "
                    class="glb-error02"
                  >
                    <div *ngIf="zipCode.errors.required">
                      郵便番号は必須です
                    </div>
                    <div *ngIf="zipCode.errors.zipCode">
                      郵便番号のフォーマットが不正です(musubi側の登録情報を確認してください)
                    </div>
                  </span>
                </dd>

                <dt class="glb-dl02-ttl">住所</dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="text"
                    class="glb-input02"
                    formControlName="address"
                    placeholder="東京都中央区築地4丁目1-17 銀座大野ビル9F"
                    disabled
                  />
                  <span
                    *ngIf="
                      address.invalid && (address.dirty || address.touched)
                    "
                    class="glb-error02"
                  >
                    <div *ngIf="address.errors.required">住所は必須です</div>
                  </span>
                </dd>

                <dt class="glb-dl02-ttl">TEL</dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="tel"
                    class="glb-input02"
                    formControlName="tel"
                    placeholder="03-5357-7853"
                    disabled
                  />
                  <span
                    *ngIf="tel.invalid && (tel.dirty || tel.touched)"
                    class="glb-error02"
                  >
                    <div *ngIf="tel.errors.required">電話番号は必須です</div>
                    <div *ngIf="tel.errors.tel">
                      電話番号のフォーマットが不正です(musubi側の登録情報を確認してください)
                    </div>
                  </span>
                </dd>

                <dt class="glb-dl02-ttl">Musubi薬局ID</dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="text"
                    class="glb-input02"
                    formControlName="musubiPharmacyId"
                    placeholder="ムスビ薬局ID"
                    disabled
                  />
                </dd>

                <dt class="glb-dl02-ttl">Musubi契約</dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="checkbox"
                    class="glb-input02"
                    formControlName="isContractedMusubi"
                    placeholder="true"
                  />
                  <span
                    *ngIf="
                      isContractedMusubi.invalid &&
                      (isContractedMusubi.dirty || isContractedMusubi.touched)
                    "
                    class="glb-error02"
                  >
                  </span>
                </dd>

                <dt class="glb-dl02-ttl">Musubi Web問診票OP契約</dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="checkbox"
                    class="glb-input02"
                    formControlName="isContractedMusubiWebMedicalQuestionnaire"
                    placeholder="false"
                  />
                </dd>

                <dt class="glb-dl02-ttl">緯度<br><span style="font-size: 8px;">※ 基本的に入力不要。GoogleMapで住所が見つからない場合のみ入力。</span></dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="text"
                    class="glb-input02"
                    formControlName="latitude"
                    placeholder="35.667686"
                  />
                  <span
                    *ngIf="
                      latitude.invalid && (latitude.dirty || latitude.touched)
                    "
                    class="glb-error02"
                  >
                    <div *ngIf="latitude.errors.float">
                      緯度を入力する場合は小数点ありの数字で入力してください。
                    </div>
                  </span>
                </dd>

                <dt class="glb-dl02-ttl">経度<br><span style="font-size: 8px;">※ 基本的に入力不要。GoogleMapで住所が見つからない場合のみ入力。</span></dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="text"
                    class="glb-input02"
                    formControlName="longitude"
                    placeholder="139.7681286"
                  />
                  <span
                    *ngIf="
                      longitude.invalid && (longitude.dirty || longitude.touched)
                    "
                    class="glb-error02"
                  >
                    <div *ngIf="longitude.errors.float">
                      経度を入力する場合は小数点ありの数字で入力してください。
                    </div>
                  </span>
                </dd>
              </dl>
            </div>

            <div class="glb-list01-cont-in type-end">
              <button
                type="submit"
                class="glb-btn04 type-large"
                [disabled]="pharmacyForm.invalid"
              >
                {{ actionName }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>
