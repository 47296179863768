import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services';
import { Observable } from 'rxjs';
import { MedicineAdmin } from '../../../core/models';

@Injectable({
  providedIn: 'root',
})
export class MedicineService extends ApiService {
  sendFile(formData: FormData): Observable<MedicineAdmin> {
    return this.putFile$<MedicineAdmin>(`medicine_admin/`, formData);
  }
}
