import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicineComponent } from './components/medicine.component';
import { MedicineRoutingModule } from './medicine-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [MedicineComponent],
  imports: [
    CommonModule,
    CoreModule,
    MedicineRoutingModule,
    ReactiveFormsModule,
  ],
})
export class MedicineModule {}
