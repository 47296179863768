import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { PqService } from '../services/pq.service';
import { datadogLogs } from '@datadog/browser-logs';

type State = 'Initialed' | 'Success' | 'Failed';

@Component({
  selector: 'app-pq',
  templateUrl: './pq.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PqComponent implements OnInit {
  readonly state$ = new BehaviorSubject<State>('Initialed');
  readonly isSubmitting$ = new BehaviorSubject(false);
  public errorMessage: string;
  public successMessage: string;

  pqForm = this.fb.group({
    pqFile: ['', [Validators.required]],
    pqFileName: ['', [Validators.required]],
    pqFileSource: ['', [Validators.required]],
  });

  constructor(private fb: FormBuilder, private pqService: PqService) {}

  get pqFile(): AbstractControl {
    return this.pqForm.get('pqFile');
  }

  changePqFile(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.pqForm.patchValue({
        pqFileSource: file,
        pqFileName: file.name,
      });
    }
  }

  submitPqFile(): void {
    const formData = new FormData();
    formData.append(
      'file',
      this.pqForm.get('pqFileSource').value,
      this.pqForm.get('pqFileName').value
    );

    this.isSubmitting$.next(true);
    this.pqService.sendFile(formData).subscribe(
      (res) => {
        this.state$.next('Success');
        this.successMessage = res.detail;
        this.pqForm.reset();
        this.isSubmitting$.next(false);
      },
      (err) => {
        datadogLogs.logger.error(JSON.stringify(err));
        if (err.error.detail) {
          this.errorMessage = err.error.detail;
        }
        this.state$.next('Failed');
        this.isSubmitting$.next(false);
      }
    );
  }

  ngOnInit(): void {
    this.state$.next('Initialed');
    this.errorMessage = '';
    this.successMessage = '';
    this.isSubmitting$.next(false);
  }
}
