import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceComponent } from './components/maintenance.component';
import { MaintenanceRoutingModule } from './maintenance-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [MaintenanceComponent],
  imports: [CommonModule, CoreModule, MaintenanceRoutingModule, ReactiveFormsModule],
})
export class MaintenanceModule {}
