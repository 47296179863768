import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { redirectLoggedInTo } from '@angular/fire/auth-guard';

const redirectLoggedInToItems = () => redirectLoggedInTo(['/']);

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/official-account/official-account.module').then(
        (m) => m.OfficialAccountModule
      ),
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
    data: { authGuardPipe: redirectLoggedInToItems },
  },
  {
    path: 'official-accounts',
    loadChildren: () =>
      import('./features/official-account/official-account.module').then(
        (m) => m.OfficialAccountModule
      ),
  },
  {
    path: 'official-accounts/:officialAccountId/pharmacies',
    loadChildren: () =>
      import('./features/pharmacy/pharmacy.module').then(
        (m) => m.PharmacyModule
      ),
  },
  {
    path: 'medicine',
    loadChildren: () =>
      import('./features/medicine/medicine.module').then(
        (m) => m.MedicineModule
      ),
  },
  {
    path: 'pq',
    loadChildren: () =>
      import('./features/pq/pq.module').then((m) => m.PqModule),
  },
  {
    path: 'medicine-question',
    loadChildren: () =>
      import('./features/medicine-question/medicine-question.module').then(
        (m) => m.MedicineQuestionModule
      ),
  },
  {
    path: 'manual',
    loadChildren: () =>
      import('./features/manual/manual.module').then((m) => m.ManualModule),
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./features/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
  },
  {
    path: 'line-users',
    loadChildren: () =>
      import('./features/line-user/line-user.module').then((m) => m.LineUserModule),
  },
  {
    path: 'kkhs-staffs',
    loadChildren: () =>
      import('./features/kkhs-staff/kkhs-staff.module').then((m) => m.KkhsStaffModule),
  },
  {
    path: 'pharmacy-bulk-register',
    loadChildren: () =>
      import('./features/pharmacy-bulk-register/pharmacy-bulk-register.module').then((m) => m.PharmacyBulkRegisterModule),
  },
  {
    path: 'pe-line-message',
    loadChildren: () =>
      import('./features/pe-line-message/pe-line-message.module').then((m) => m.PeLineMessageModule),
  },
  {
    path: 'bulk-delivery-contents',
    loadChildren: () =>
      import('./features/bulk-delivery-content/bulk-delivery-content.module').then((m) => m.BulkDeliveryContentModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: 'Not Found' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
