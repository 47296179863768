import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services';
import { Maintenance } from '../../../core/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService extends ApiService {
  readonly maintenances$ = new BehaviorSubject<Maintenance[] | null>([]);

  getMaintenances() {
    this.get$<Maintenance[]>('maintenance/').subscribe(
      (maintenance) => this.maintenances$.next(maintenance) // error, completeは無視
    );
  }

  toggleMaintenceFlag(key: string): Observable<Maintenance> {
    return this.patch$<Maintenance>("maintenance/", JSON.stringify({key: key}));
  }
}
