import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MedicineService } from '../services/medicine.service';
import { datadogLogs } from '@datadog/browser-logs';

type State = 'Initialed' | 'Success' | 'Failed';

@Component({
  selector: 'app-medicine',
  templateUrl: './medicine.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicineComponent implements OnInit {
  readonly state$ = new BehaviorSubject<State>('Initialed');
  readonly isSubmitting$ = new BehaviorSubject(false);
  public errorMessage: string;
  public successMessage: string;

  medicineForm = this.fb.group({
    medicineFile: ['', [Validators.required]],
    medicineFileName: ['', [Validators.required]],
    medicineFileSource: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private medicineService: MedicineService
  ) {}

  get medicineFile(): AbstractControl {
    return this.medicineForm.get('medicineFile');
  }

  changeMedicineFile(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.medicineForm.patchValue({
        medicineFileSource: file,
        medicineFileName: file.name,
      });
    }
  }

  submitMedicineFile(): void {
    const formData = new FormData();
    formData.append(
      'file',
      this.medicineForm.get('medicineFileSource').value,
      this.medicineForm.get('medicineFileName').value
    );

    this.isSubmitting$.next(true);
    this.medicineService.sendFile(formData).subscribe(
      (res) => {
        this.state$.next('Success');
        this.successMessage = res.detail;
        this.medicineForm.reset();
        this.isSubmitting$.next(false);
      },
      (err) => {
        datadogLogs.logger.error(JSON.stringify(err));
        if (err.error.detail) {
          this.errorMessage = err.error.detail;
        }
        this.state$.next('Failed');
        this.isSubmitting$.next(false);
      }
    );
  }

  ngOnInit(): void {
    this.state$.next('Initialed');
    this.errorMessage = '';
    this.successMessage = '';
    this.isSubmitting$.next(false);
  }
}
