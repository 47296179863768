import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { LineOfficialAccount } from '../../../../core/models';
import { OfficialAccountService } from '../../services/official-account.service';
import { datadogLogs } from '@datadog/browser-logs';

@Component({
  selector: 'app-official-account-add',
  templateUrl: './official-account-add.component.html',
})
export class OfficialAccountAddComponent implements OnInit {
  readonly actionName = '登録';
  readonly isFailed$ = new BehaviorSubject(false);
  readonly isSubmitting$ = new BehaviorSubject(false);

  constructor(
    private officialAccountService: OfficialAccountService,
    private router: Router
  ) {}

  onSubmit(lineOfficialAccount: LineOfficialAccount): void {
    // ダブルクリック防止
    this.isSubmitting$.next(true);

    this.officialAccountService
      .createOfficialAccount(lineOfficialAccount)
      .subscribe(
        () => {
          this.router.navigate([`/official-accounts`]);
        },
        (err) => {
          datadogLogs.logger.error(JSON.stringify(err));
          this.isFailed$.next(true);
          this.isSubmitting$.next(false);
        }
      );
  }

  ngOnInit(): void {
    this.isFailed$.next(false);
    this.isSubmitting$.next(false);
  }
}
