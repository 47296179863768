<main>
  <section class="glb-section02">
    <div class="glb-section02-ttl">
      <p class="glb-section02-text">LINE公式アカウント{{ actionName }}</p>
    </div>
  </section>

  <section class="glb-section01 type-start">
    <div class="glb-section01-cont type-narrow">
      <div class="glb-section01-ttl">
        <h2 class="glb-ttl01">LINE公式アカウント情報 (カケハシ管理用)</h2>
      </div>
      <div class="glb-section01-base">
        <div class="glb-list01-cont">
          <div class="glb-list01-ttl" *ngIf="isFailed$ | async">
            <p class="glb-error02">
              LINE公式アカウントの{{ actionName }}に失敗しました
            </p>
          </div>
          <form
            [formGroup]="officialAccountForm"
            (ngSubmit)="submitOfficialAccount()"
          >
            <div>
              <dl class="glb-dl02">
                <dt class="glb-dl02-ttl">法人グループ名</dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="text"
                    class="glb-input02"
                    formControlName="corporationName"
                    placeholder="株式会社カケハシグループ"
                  />
                  <span
                    *ngIf="
                      corporationName.invalid &&
                      (corporationName.dirty || corporationName.touched)
                    "
                    class="glb-error02"
                  >
                    <div *ngIf="corporationName.errors.required">
                      法人グループ名は必須です
                    </div>
                  </span>
                </dd>
                <dt class="glb-dl02-ttl">LINE公式アカウント名</dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="text"
                    class="glb-input02"
                    formControlName="name"
                    placeholder="くさび薬局LINE公式アカウント"
                  />
                  <span
                    *ngIf="name.invalid && (name.dirty || name.touched)"
                    class="glb-error02"
                  >
                    <div *ngIf="name.errors.required">
                      LINE公式アカウント名は必須です
                    </div>
                  </span>
                </dd>
                <dt class="glb-dl02-ttl">トラッキングURL</dt>
                <dd class="glb-dl02-cont">
                  <input
                    type="checkbox"
                    class="glb-input02"
                    formControlName="isTrackingUrlEnabled"
                    placeholder="true"
                  />
                </dd>
                <dt class="glb-dl02-ttl">薬局管理画面初期設定</dt>
                <dd class="glb-dl02-cont">
                  <select formControlName="isLineSettingLocked" class="glb-select01">
                    <option value="">選択してください</option>
                    <option
                      *ngFor="let LINE_SETTING_LOCKED_VALUE of LINE_SETTING_LOCKED_SELECT_VALUES"
                      [value]="LINE_SETTING_LOCKED_VALUE.value"
                    >
                      {{LINE_SETTING_LOCKED_VALUE.label}}
                    </option>
                  </select>
                  <span
                    *ngIf="isLineSettingLocked.invalid && (isLineSettingLocked.dirty || isLineSettingLocked.touched)"
                    class="glb-error02"
                  >
                    <div *ngIf="isLineSettingLocked.errors.required">
                      薬局管理画面初期設定は必須です
                    </div>
                  </span>
                </dd>
              </dl>
            </div>
            <div class="glb-list01-cont-in type-end">
              <button
                type="submit"
                class="glb-btn04 type-large"
                [disabled]="
                  officialAccountForm.invalid || (isSubmitting$ | async)
                "
              >
                保存
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>
