import { Component } from '@angular/core';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { LineUserService } from '../services/line-user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-line-user',
  templateUrl: './line-user.component.html',
  styleUrls: ['./line-user.component.scss'],
})
export class LineUserComponent {
  readonly lineUsers$ = this.lineUserService.lineUsers$;

  searchForm = this.fb.group({
    searchParam: [''],
  });

  get searchParam(): AbstractControl {
    return this.searchForm.get('searchParam');
  }

  constructor(
    private fb: FormBuilder,
    private lineUserService: LineUserService,
    private toastr: ToastrService
  ) {}

  submitSearch(): void {
    this.lineUserService.getLineUsers(this.searchParam.value);
  }

  async submitDelete(id): Promise<void> {
    const isConfirm = confirm(`LINEユーザーを削除してよろしいですか？`);
    if (isConfirm) {
      this.lineUserService.deleteLineUsers(id).subscribe({
        next: (res) => {
          this.toastr.info('LINEユーザーを削除しました', '');
          this.lineUserService.getLineUsers(this.searchParam.value);
        },
        error: () => {
          this.toastr.error('LINEユーザーの削除に失敗しました', '');
        },
      });
    }
  }
}
