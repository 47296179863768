import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/core/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { PharmacyService } from '../../services/pharmacy.service';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { LineOfficialAccount, Pharmacy } from '../../../../core/models';
import { filter, switchMap } from 'rxjs/operators';
import { datadogLogs } from '@datadog/browser-logs';


@Component({
  selector: 'app-line-official-account-update-form',
  templateUrl: './line-official-account-update-form.component.html',
  styleUrls: ['./line-official-account-update-form.component.scss']
})
export class LineOfficialAccountUpdateFormComponent implements OnInit {
  errorMessage = new BehaviorSubject<string>('');
  readonly lineOfficialAccountId = this.route.snapshot.paramMap.get(
    'officialAccountId'
  );
  @Input() pharmacy: Pharmacy;
  readonly LineOfficialAccounts$ = new BehaviorSubject<LineOfficialAccount[]>(null);

  LineOfficialAccountForm = this.fb.group({
    LineOfficialAccountId: ['']
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private pharmacyService: PharmacyService
  ) {
  }

  ngOnInit(): void {
    this.pharmacyService.getLineOfficialAccounts().subscribe({
      next: (res) => {
        this.LineOfficialAccounts$.next(res);
      }
    });
    this.LineOfficialAccountForm.patchValue({
      LineOfficialAccountId: this.lineOfficialAccountId
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `${this.pharmacy.name}のLINE公式アカウントを${this.previousLineOfficialAccountName}から${this.selectLineOfficialAccountName}に変更しますか？`,
        confirmText: 'OK',
        cancelText: 'キャンセル',
        errorText: this.errorMessage.value
      },
      disableClose: false
    });

    dialogRef.afterClosed().pipe(
      filter((result) => result),
      switchMap(() => this.pharmacyService.updateLineOfficialAccountPharmacy(this.selectedLineOfficialAccountId.value, this.pharmacy.id))
    ).subscribe({
      next: () => {
        this.toastr.success('LINE公式アカウントを変更しました。');
        this.router.navigate(['/official-accounts']);
      },
      error: (result) => {
        datadogLogs.logger.error(JSON.stringify(result));
        if (result.error === '薬局に紐付いている患者が存在するため、変更できません') {
          alert(`変更に失敗しました。${result.error}。LIFFで該当の患者を削除して再度お試しください。`);
          return;
        }
        if (result.status !== 500) {
          alert(`変更に失敗しました。${result.error}。`);
          return;
        }
        alert('変更に失敗しました。予期せぬエラーが起こっています。エンジニアに連絡してください。');
      }
    });
  }

  lineOfficialAccountName(lineOfficialAccount: LineOfficialAccount): string {
    return `${lineOfficialAccount.name}${lineOfficialAccount.corporation_name ? `(${lineOfficialAccount.corporation_name})` : ''}`;
  }

  get selectLineOfficialAccount(): LineOfficialAccount {
    return this.LineOfficialAccounts$.value.find(
      (lineOfficialAccount) => lineOfficialAccount.id === Number(this.selectedLineOfficialAccountId.value));
  }

  get selectedLineOfficialAccountId(): AbstractControl {
    return this.LineOfficialAccountForm.get('LineOfficialAccountId');
  }

  get selectLineOfficialAccountName(): string {
    return `${this.selectLineOfficialAccount.name}${this.selectLineOfficialAccount.corporation_name ? `(${this.selectLineOfficialAccount.corporation_name})` : ''}`;
  }

  get previousLineOfficialAccount(): LineOfficialAccount {
    return this.LineOfficialAccounts$.value.find(
      (lineOfficialAccount) => lineOfficialAccount.id === Number(this.lineOfficialAccountId));
  }

  get previousLineOfficialAccountName(): string {
    return `${this.previousLineOfficialAccount.name}${this.previousLineOfficialAccount.corporation_name ? `(${this.previousLineOfficialAccount.corporation_name})` : ''}`;
  }
}
