import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../../../core/services';
import { Pharmacy } from '../../../core/models/pharmacy';
import { LineOfficialAccount, MusubiPharmacyInfo, PharmacyRegistration } from '../../../core/models';

@Injectable({
  providedIn: 'root',
})
export class PharmacyService extends ApiService {
  readonly pharmacies$ = new BehaviorSubject<Pharmacy[] | null>([]);

  getPharmacies(officialAccountId: string): void {
    this.get$<Pharmacy[]>(
      `line_official_accounts/${officialAccountId}/pharmacies/`
    ).subscribe(
      (pharmacies) => this.pharmacies$.next(pharmacies) // error, completeは無視
    );
  }

  getMusubiPharmacyInfo$(
    medicalInstitutionCode: string
  ): Observable<MusubiPharmacyInfo[]> {
    return this.get$<MusubiPharmacyInfo[]>(
      `musubi_pharmacy_info/?medical_institution_code=${medicalInstitutionCode}`
    );
  }

  getPharmacy(officialAccountId: string, id: string): Observable<Pharmacy> {
    return this.get$<Pharmacy>(
      `line_official_accounts/${officialAccountId}/pharmacies/${id}/`
    );
  }

  createPharmacy(
    officialAccountId: string,
    pharmacyRegistration: PharmacyRegistration
  ): Observable<Pharmacy> {
    return this.post$<Pharmacy>(
      `line_official_accounts/${officialAccountId}/pharmacies/`,
      JSON.stringify(pharmacyRegistration)
    );
  }

  editPharmacy(
    officialAccountId: string,
    id: string,
    pharmacyRegistration: PharmacyRegistration
  ): Observable<Pharmacy> {
    return this.put$<Pharmacy>(
      `line_official_accounts/${officialAccountId}/pharmacies/${id}/`,
      JSON.stringify(pharmacyRegistration)
    );
  }

  getLineOfficialAccounts(): Observable<any> {
    return this.get$<LineOfficialAccount[]>(`line_official_accounts/`);
  }

  updateLineOfficialAccountPharmacy(officialAccountId: string, pharmacyId: number): Observable<void> {
    return this.patch$<void>(`pharmacies/${pharmacyId}/`, JSON.stringify({line_official_account_id: officialAccountId}));
  }

  togglePharmacyCancelStatus(id: number): Observable<Pharmacy> {
    return this.patch$<Pharmacy>(`pharmacy/${id}/cancel_state/`, null);
  }

}
