import { AbstractControl, ValidationErrors } from '@angular/forms';

function isEmptyInputValue(val: any) {
  return val == null || val.length === 0;
}

export class CustomValidators {
  static number(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }
    return /^\d+$/.test(control.value) ? null : { number: true };
  }

  static float(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }
    return /^\d+\.\d+$/.test(control.value) ? null : { float: true };
  }

  static tel(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }
    return /^[\d-]{10,13}$/.test(control.value) ? null : { tel: true };
  }

  static medicalInstitutionCode(
    control: AbstractControl
  ): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }
    return /^\d{10}$/.test(control.value)
      ? null
      : { medicalInstitutionCode: true };
  }

  static zipCode(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }
    return /^\d{3}-*\d{4}$/.test(control.value) ? null : { zipCode: true };
  }
}
