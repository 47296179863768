import { CoreModule } from '../../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineUserComponent } from './components/line-user.component';
import { LineUserRoutingModule } from './line-user-routing.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [LineUserComponent],
  imports: [
    CommonModule,
    CoreModule,
    LineUserRoutingModule,
    ReactiveFormsModule,
  ],
})
export class LineUserModule {}
