import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { datadogLogs } from '@datadog/browser-logs';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  readonly kkhsDomain = 'kakehashi.life';
  readonly isFailed$ = new BehaviorSubject(false);

  constructor(private router: Router, private afAuth: AngularFireAuth) {}

  googleLogin() {
    const provider = new auth.GoogleAuthProvider();
    provider.setCustomParameters({
      hd: this.kkhsDomain,
    });

    return this.afAuth
      .signInWithPopup(provider)
      .then(() => {
        this.router.navigate(['/']);
      })
      .catch((err) => {
        datadogLogs.logger.error(JSON.stringify(err));
        this.isFailed$.next(true);
      });
  }

  logout() {
    this.afAuth
      .signOut()
      .then(() => {
        this.router.navigate(['/login']);
      })
      .catch((err) => {});
  }
}
