import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { KkhsStaff } from 'src/app/core/models';
import { ApiService } from 'src/app/core/services';
import { FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/core/utils/custom-validators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-kkhs-staff',
  templateUrl: './kkhs-staff.component.html',
  styleUrls: ['./kkhs-staff.component.scss']
})
export class KkhsStaffComponent implements OnInit {
  readonly kkhsStaffs$ = new BehaviorSubject<
  KkhsStaff[] | null
  >([]);
  readonly loginUser$ = new BehaviorSubject<KkhsStaff | null>(null);

  createForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  get email(): AbstractControl {
    return this.createForm.get('email');
  }

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getKkhsStaffs();
  }

  private getKkhsStaffs(): void {
    this.apiService.get$<KkhsStaff[]>(`kkhs_staffs/`).subscribe(
      (kkhsStaffs) => {
        kkhsStaffs.forEach(staff => {
          if (staff.is_login_user) {
            this.loginUser$.next(staff);
          }
        });
        this.kkhsStaffs$.next(kkhsStaffs);
      }
    );
  }

  deleteStaff(id: string): void {
    const isConfirm = confirm('社内ユーザーを削除してよろしいですか？');
    if (isConfirm) {
      this.apiService.delete$<KkhsStaff>(`kkhs_staffs/${id}/`).subscribe(
        {
          next: () => {
            this.toastr.info('社内ユーザーを削除しました', '');
            this.getKkhsStaffs();
          },
          error: () => {
            this.toastr.error('社内ユーザーの削除に失敗しました', '');
          },
        }
      );
    }
  }

  createStaff(): void {
    if (this.createForm.invalid) {
      return;
    }

    this.apiService.post$<KkhsStaff>('kkhs_staffs/', JSON.stringify({email: this.email.value})).subscribe(
      {
        next: () => {
          this.toastr.info('社内ユーザーを作成しました', '');
          this.getKkhsStaffs();
        },
        error: () => {
          this.toastr.error('社内ユーザーの作成に失敗しました', '');
        },
      }
    );
  }
}
