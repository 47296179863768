import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { LoadingComponent } from './components/loading/loading.component';
import { NavbarComponent } from './components/navbar/navbar.component';

import { ApiService, PageTitleService, SessionService } from './services';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';

@NgModule({
  declarations: [NavbarComponent, LoadingComponent, BreadcrumbsComponent],
  imports: [CommonModule, HttpClientModule, RouterModule],
  providers: [ApiService, PageTitleService, SessionService],
  exports: [NavbarComponent, LoadingComponent, BreadcrumbsComponent],
})
export class CoreModule {}
