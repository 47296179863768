import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfficialAccountAddComponent } from './pages/official-account-add/official-account-add.component';
import { OfficialAccountEditComponent } from './pages/official-account-edit/official-account-edit.component';
import { OfficialAccountListComponent } from './pages/official-account-list/official-account-list.component';
import { AuthGuard } from '../../core/guards/auth.guard';
import { redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    component: OfficialAccountListComponent,
    canActivate: [AuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      title: 'LINE公式アカウント一覧',
    },
  },
  {
    path: 'add',
    component: OfficialAccountAddComponent,
    canActivate: [AuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      title: 'LINE公式アカウント登録',
    },
  },
  {
    path: ':id/edit',
    component: OfficialAccountEditComponent,
    canActivate: [AuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      title: 'LINE公式アカウント編集',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OfficialAccountRoutingModule {}
